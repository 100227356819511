import React, { Component } from 'react';
import '../../App.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactDOM from 'react-dom';
import App from '../../App';

class Help extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
        open: true,
    }
  }

  requestGeo(){
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            function success(position){
                console.log("It worked!");
                window.location.reload();
                ReactDOM.render(<App />, document.getElementById('root')); 
            },
            function error(error_message) {
                console.log(error_message);
            }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
  }
 handleClose() {
    this.setState({
        open: false,
    })
    window.close();
  }
    render() {
      return (
        <div>
            <Dialog
        open={this.state.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Welcome to Help!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            In order to find the nearest available AEDs, Help needs your permission to fetch your current location.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Exit
          </Button>
          <Button onClick={this.requestGeo} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
        </div>
      );
    }
  }
   
  export default (Help);