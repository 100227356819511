import React, { Component } from "react";
import "../../App.css";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import "typeface-open-sans";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import ReactHtmlParser from 'react-html-parser';

const theme = createMuiTheme({
  palette: {
    primary: { main: red[500] }
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

const styles = theme => ({
  root: {
    height: "100%",
    flexGrow: 1,
    margin: 0,
    padding: 0
  },
  paperStyle: {
    "text-decoration": "none",
    "align-items": "center",
    backgroundColor: "transparent",
    textAlign: "center",
    "justify-content": "center",
    display: "flex"
  },
  linkStyle:{
    "text-decoration": "none",
  }
});

class Help extends Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.customDialog = this.customDialog.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.state = {
      isActive: "yes",
      currentSlide: "",
      dialogTitle: "",
      dialogDesc: "",
      open: false,
      height: (window.innerHeight - 56) / 4
    };
  }
  customDialog(title){
    if (title !==4){
      this.setState({
        isActive: true,
      })
    }
    if (title===1){
      this.setState({
        dialogTitle: "1) Assess",
        dialogDesc: "Ensure the area is safe before approaching the casualty. <br><br> If the casualty is not responsive and not breathing: <br> - <strong>Start resuscitation</strong><br> - Contact the emergency services using the speaker mode. <br>- If possible, ask a passer-by to bring an AED.<br><br> If the casualty is unresponsive but breathing normally, place them in the recovery position.",
      })
    }
    else if (title===2){
      this.setState({
        dialogTitle: "2) Adult Resuscitation",
        dialogDesc: "With your hands in the position as shown, give 30 chest compressions, followed by 2 rescue breaths.",
      })
    }
    else if (title===3){
      this.setState({
        dialogTitle: "3) AED",
        dialogDesc: "Where possible, ensure someone is performing CPR as you prepare to use the AED. <br><br>Place the AED near the casualty.<br><br>Turn on the AED. A voice will guide on how to set up the AED.<br><br>1) Place the electrode pads on the casualty's bare chest as seen here: <br><br>2) The AED will automatically determine if a shock is to be delivered.<br><br>3) If a shock is needed, double check <b>everyone is clear of the casualty</b>. Ensure <b>everyone is clear</b> before pressing the shock button to deliver a shock. <br><br>4) Once the shock has been delivered, continue CPR and listen to the AED for any voice prompts. ",
      })
    }
    else if (title===4){
      this.setState({
        isActive: null,
        dialogTitle: "Recovery Position",
        dialogDesc: "",
      })
    }
      this.setState({
        currentSlide: title,
        open: true,
      })
    }
  nextPage(current){
    if (current>2){
      this.customDialog(1);
    }
    else{
      this.customDialog(current+1);
    }
    
  }
  resize() {
    console.log("called");
    this.setState({
      height: (window.innerHeight - 56) / 4
    });
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  handleClose() {
    this.setState({
        open: false,
    })
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <Grid container direction="column" justify="center">
            <Link style={{textDecoration:"none", color: "inherit"}} onClick={()=>this.customDialog(1)}>
            <Box height={this.state.height} className={classes.paperStyle}>
              <Typography  className={classes.linkStyle} variant="h4">Assess</Typography>
            </Box>
            </Link>
            <Divider />
            <Link style={{textDecoration:"none", color: "inherit"}} onClick={()=>this.customDialog(2)}>
            <Box height={this.state.height} className={classes.paperStyle}>
              <Typography className={classes.linkStyle} variant="h4">Adult Resuscitation</Typography>
            </Box>
            </Link>
            <Divider />
            <Link style={{textDecoration:"none", color: "inherit"}}  onClick={()=>this.customDialog(3)}>
            <Box height={this.state.height} className={classes.paperStyle}>
              <Typography className={classes.linkStyle} variant="h4">AED</Typography>
            </Box>
            </Link>
            <Divider />
            <Link style={{textDecoration:"none", color: "inherit"}} onClick={()=>this.customDialog(4)}>
            <Box height={this.state.height} className={classes.paperStyle}>
              <Typography className={classes.linkStyle} variant="h4">Recovery Position</Typography>
            </Box>
            </Link>
          </Grid>
          <Dialog
        open={this.state.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          { ReactHtmlParser (this.state.dialogDesc) }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        { this.state.isActive ? <Button onClick={()=>this.nextPage(this.state.currentSlide)} color="primary">Next</Button> : null}
        <Button onClick={this.handleClose} color="primary">
            Exit
        </Button>
        </DialogActions>
      </Dialog>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(Help);
