import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import WelcomeModal from './views/WelcomeModal/WelcomeModal';
import * as serviceWorker from './serviceWorker';

navigator.permissions.query({name:'geolocation'}).then(function(result) {
    if (result.state === 'granted') {
        ReactDOM.render(<App />, document.getElementById('root'));  
    } else if (result.state === 'prompt') {
        ReactDOM.render(<WelcomeModal />, document.getElementById('root'));
    }
    else {
        ReactDOM.render(<App />, document.getElementById('root'));  
    }
    // Don't do anything if the permission was denied.
  });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
