import React, { Component } from 'react';
import '../../App.css';
import { withStyles } from "@material-ui/core/styles";
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import aedlocsjson from '../Emergency/aedlocs.json';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "20vh",
    margin: 0,
    padding: 0,
  },
  main: {
    backgroundColor: 'transparent',
  },
  AEDText:{
    color: "white",
    margin: 0,
    fontSize: "4vh",
  },
  AEDLocText:{
    color: "white",
    margin:0,
    fontSize: "2vh",
  },
  card: {
    padding: 0,
    color: "white",
    backgroundColor: "inherit",
    maxWidth: "100%",
    height: "49vh",
    "margin-bottom": "2vh",
  },
  mapSize:{
    height: "93vh",
  },
  card3: {
    color: grey[50],
    backgroundColor: 'transparent',
    maxWidth: "100%",
    height: "24vh",
    margin: 0,
    padding: 0,
  },
  bigButtonText: {
    color: red[500],
    "padding-top": "0.5vh",
    "padding-bottom": "0.5vh",
    "align-items": "center",
    "justify-content": "center",
    fontSize: "2vh",
  },
  littleButtonText: {
    color: grey[50],
    "align-items": "center",
    "justify-content": "center",
    fontSize: "2vh",
  },
  number: {
    color: grey[50],
    backgroundColor: 'transparent',
    width: "100%",
    position: "fixed",
    bottom: 0
  },
  mapImage: {
    height: "30vh",
    width: "100%"
  },
  paper: {
    backgroundColor: "transparent",
    padding: theme.spacing(1),
    height: "7vh",
    textAlign: 'center',
    "align-items": "center",
    "justify-content": "center",
  },
  recoveryPos: {
    textAlign: 'center',
    
  },
  CPR:{
    "margin-bottom": "1vh",
    
  },
});
class Finder extends Component {
constructor(props){
  super(props);
        this.platform = null;
        this.map = "";
        this.getData = this.getData.bind(this);
        this.state = {
          app_id : process.env.REACT_APP_HERE_APPID,
          app_code : process.env.REACT_APP_HERE_APPCODE,
          apikey : process.env.REACT_APP_HERE_APIKEY,
          useHTTPS: true,
          longitude: "Location Off",
          center: {
            lat: props.lat,
            lng: props.lng,
          },
          latitude: "",
          displayLat: "",
          zoom: 13,
          theme: props.theme,
          style: props.style,
          url:
            "https://image.maps.api.here.com/mia/1.6/mapview?w=400&h=300&z=15&t=5&poitxs=16&poitxc=black&poifc=yellow",
          points: [],
        };
        this.currentTotal = 0;
}
async getData(self,markerGroup) {
  console.log("I got called");
  var locations = aedlocsjson;
  locations.forEach(function(value,index){
      var coords = {lat:value.Latitude, lng: value.Longitude}
      var marker = new window.H.map.Marker(coords,  {
        volatility: true,
      });
      marker.setData(
        {facility: value.Name,
        address: value.Address}
      )
      markerGroup.addObject(marker);
      
      })
      self.map.addObject(markerGroup);
}
componentDidMount() {
  var self = this;
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      function success(position) {
        // for when getting location is a success
        self.setState({
          latitude: position.coords.latitude,
          displayLat: position.coords.latitude + ",",
          longitude: position.coords.longitude,
        })
  self.platform = new window.H.service.Platform(self.state);
  var layer = self.platform.createDefaultLayers();
  var container = document.getElementById('here-map2');
  self.map = new window.H.Map(container, layer.normal.map, {
      center: {lat: position.coords.latitude, lng: position.coords.longitude},
      zoom: self.state.zoom,
    })
  var events = new window.H.mapevents.MapEvents(self.map);
  var behavior = new window.H.mapevents.Behavior(events);
  var ui = new window.H.ui.UI.createDefault(self.map, layer)
  var markerGroup = new window.H.map.Group();
  self.getData(self,markerGroup); 
  markerGroup.addEventListener('tap', function (evt) {
    // event target is the marker itself, group is a parent event target
    // for all objects that it contains
    var bubble =  new window.H.ui.InfoBubble(evt.target.getGeometry(), {
      // read custom data
      content: evt.target.getData().address
    });
    // show info bubble
    ui.getBubbles().forEach(bub => ui.removeBubble(bub));
    ui.addBubble(bubble);
  }, false);
},
function error(error_message) {
  // for when getting location results in an error
  console.error(
    "An error has occured while retrieving location",
    error_message
  );
}
);
} else {
// geolocation is not supported
// get your location some other way
console.log("geolocation is not enabled on this browser");
}
}
    render() {
      const { classes } = this.props;
      return (
        <div className={classes.mapSize} >
        <div id="here-map2" style={{width: '100%', height: '100%', background: 'grey' }} />
         </div>
      );
    }
  }
   
  export default withStyles(styles)(Finder);