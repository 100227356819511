import React, { Component } from "react";
import "../../App.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import 'typeface-open-sans';
import aedlocsjson from '../Emergency/aedlocs.json';
import ReactHtmlParser from 'react-html-parser';
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import fallback from './fallback.png';
import flag from 'country-code-emoji';
const api = require("@what3words/api");
api.setOptions({ key: process.env.REACT_APP_API_KEY });


const theme = createMuiTheme({
  palette: {
    primary: { main: red[500] },
    secondary: { main:grey[900] }, 
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const styles = theme => ({
  root: {
    backgroundColor: 'transparent',
    flexGrow: 1,
    height: "20vh",
    margin: 0,
    padding: 0,
  },
  main: {
    backgroundColor: 'transparent',
  },
  AEDText:{
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: red[500],
    margin: 0,
    fontSize: "4vh",
  },
  AEDLocText:{
    fontFamily: "Open Sans",
    color: grey[900],
    margin:0,
    fontSize: "2vh",
  },
  card: {
    fontFamily: "Open Sans",
    padding: 0,
    color: "white",
    backgroundColor: "inherit",
    maxWidth: "100%",
    height: "49vh",
    "margin-bottom": "2vh",
  },
  mapSize:{
    height: "24vh",
    width: "100vw",
  },
  mapImage: {
    height: "30vh",
    width: "100%",
  },
  card3: {
    "font-family": "Open Sans",
    backgroundColor: 'transparent',
    maxWidth: "100%",
    height: "42vh",
    margin: 0,
    "margin-top": "2vh",
    padding: 0,
  },
  w3wlinkStyle: {
    "text-decoration": "none",
  },
  mIcon: {
    margin: theme.spacing(1),
    fontSize: "inherit",
    display: "inline-flex",
    "align-self": "center",
    position: "relative",
    top: "1.48vh",
  },
  AEDBox: {
    overflow: "hidden",
    height: "14vh",
  },
  bigButtonText: {
    paddingLeft: theme.spacing(1),

  },
  paper: {
    lineHeight: "7vh",
    backgroundColor: "transparent",
    padding: theme.spacing(1),
    height: "7vh",

  },
});
class Emergency extends Component {
  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.imageError = this.imageError.bind(this);
    this.platform = null;
    this.map = null;
    this.w3wLocs = "";
    this.setOpen = "false";
    this.w3wNearPos = "";
    this.w3wLocsLink = "";
    this.w3wCountry = "";
    this.nearest = [];
    this.location = [];
    this.emergencyNum = "";
    this.currentLocPlace = "Current Location  ";
    this.state = {
      data: [],
      useHTTPS: true,
      app_id : process.env.REACT_APP_HERE_APPID,
      app_code : process.env.REACT_APP_HERE_APPCODE,
      longitude: "",
    };
    this.currentTotal = 0;
  }
  handleClose() {
    this.setState({
      setOpen: false,
    });
  }
  handleClick(){
    var self=this;
    self.setState({
      setOpen: true,
    });
  }
  async getData(self) {
    var locations=aedlocsjson
      var coords =  {lat:self.state.latitude, lng: self.state.longitude};
      function distance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit==="K") { dist = dist * 1.609344 }
        if (unit==="N") { dist = dist * 0.8684 }
        return dist
      }
      var closestDist = 10000;
      var closestIndex = -1;
      for (var i = 0; i < locations.length; i++) {
          var distanceToAED = distance(coords.lat, coords.lng, locations[i].Latitude, locations[i].Longitude, "K")
          if ( distanceToAED < closestDist) {
              closestDist = distanceToAED;
              closestIndex = i;
          }
      }
      var mapWidth = window.innerWidth;
      var mapHeight = Math.round(window.innerHeight * 0.24);
      self.setState({
      nearest: locations[closestIndex],
      location: [coords.lat,coords.lng],
      mapImage: "https://image.maps.api.here.com/mia/1.6/mapview?app_id=" + 
      self.state.app_id + "&app_code=" + self.state.app_code + 
      "&w=" + mapWidth + "&h=" + mapHeight +
      "&c=" + coords.lat + "," + coords.lng +
      "&z=15" +
      "&tx0=" + locations[closestIndex].Latitude + "," + locations[closestIndex].Longitude +";AED;;;20",
      aedText: locations[closestIndex].Name,
      aedlocText: "Nearest AED: " + locations[closestIndex].Address,
      description: locations[closestIndex].Description,
      whereToGo: "",
      navigationLink: "<a style='text-decoration: none; color:inherit; font-size:2vh' color=primary href='https://www.google.com/maps/dir/?api=1&destination=" + locations[closestIndex].Address + "'>Directions</a>",
    });
  }
  imageError(){
    var self=this;
    self.setState({
      mapImage: fallback,
    })
  }
  resize() {
    var mapWidth = window.innerWidth;
    var mapHeight = Math.round(window.innerHeight * 0.24);
    console.log("called");
    this.setState({
      mapImage: "https://image.maps.api.here.com/mia/1.6/mapview?app_id=" + 
      this.state.app_id + "&app_code=" + this.state.app_code + 
      "&w=" + mapWidth + "&h=" + mapHeight +
      "&c=" + this.state.location[0] + "," + this.state.location[1] +
      "&z=15" +
      "&tx0=" + this.state.nearest.Latitude + "," + this.state.nearest.Longitude +";AED;;;20",
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize);
   var self = this;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          // for when getting location is a success
          self.setState({
            currentLocPlace: "Current Location",
            latitude: position.coords.latitude,
            displayLat: position.coords.latitude + ",",
            longitude: position.coords.longitude,
          })
          self.getData(self);
          api.convertTo3wa({
              lat: self.state.latitude,
              lng: self.state.longitude
            })
            .then(function(data) {
              self.setState({
                w3wLocs: data.words,
                w3wLocsLink: "https://map.what3words.com/" + data.words,
                w3wNearPos: data.nearestPlace,
                w3wCountry: data.country
              });
            
            fetch("https://raw.githubusercontent.com/shanezzar/JSON-of-emergency-telephone-numbers/master/data.json")
  .then(response => response.json())
  .then((jsonData) => {
    // jsonData is parsed json object received from url
    var data = (jsonData.data);
    var ind = -1
    data.forEach(function(value, index, array) {
        if (value.Country.ISOCode === self.state.w3wCountry){
          ind = index;
        }
  });
  var landFlag = flag(self.state.w3wCountry);
  self.setState({
    emergencyNum: "<div style='font-size:2vh'>Unresponsive & not breathing?</div><div style='font-size:2vh;color:#f44336;'> Call " + data[ind].Ambulance.All[0] + " (" + landFlag + ") immediately</div>"
  })
});

  })
  .catch((error) => {
    self.handleClick();
    self.setState({
      emergencyNum: "",
    })
    console.error(error)
  })

    },
  function error(error_message) {
    // for when getting location results in an error
    console.error(
      "An error has occured while retrieving location",
      error_message
    );
    self.setState({
      emergencyNum: "Location Unavailable",
    })
  }
);
} else {
// geolocation is not supported
// get your location some other way
console.log("geolocation is not enabled on this browser");
}

  }
  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.mapSize} >
          <Card className={classes.card}>
        <div className={classes.mapSize} >
        <div className="mapImage" style={{width: '100%', height: '100%', background: 'grey' }}>
        <img alt="" className={classes.mapSize} src={this.state.mapImage} onError={this.imageError} />
        </div>
         </div>
        <Snackbar anchorOrigin={{vertical: "top", horizontal: "left",}} open={this.state.setOpen} autoHideDuration={6000} onClose={this.handleClose} message={<span>Limited- you are offline</span>} 
        action={[<IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}><CloseIcon /> </IconButton>]}
        />
        <CardContent className={classes.AEDBox} id="textToRemove">
          <Typography gutterBottom className={classes.AEDText} variant="h2" component="h2">
            {this.state.aedText}
          </Typography>
          <Typography variant="body2" className={classes.AEDLocText} color="secondary" component="p">
            {this.state.aedlocText}
          </Typography>
          <Typography variant="body2" className={classes.AEDLocText} color="secondary" component="p">
            { ReactHtmlParser (this.state.description) }
            </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary">
            { ReactHtmlParser (this.state.navigationLink)}
            </Button>
        </CardActions>
      </Card>
           </div>
        </Card>
        <Paper className={classes.paper + ' ' + classes.CPR}>
          <Typography className ={classes.bigButtonText} variant="h6" component="h2">
                { ReactHtmlParser (this.state.emergencyNum)}
          </Typography>
          </Paper>
          <Card className={classes.card3}>
            <CardContent>
              <Typography className={classes.title} color="secondary" gutterBottom>
                {this.state.currentLocPlace}
              </Typography>
              <div>
                <Typography variant="h5" component="h2">
                <Link style={{ textDecoration: 'none' }} color="primary" href={this.state.w3wLocsLink} target="_blank" rel="noopenner" >{this.state.w3wLocs}</Link>
                </Typography>
                <Typography className={classes.pos}>
                    {this.state.w3wNearPos}
                    <br />
                    {this.state.displayLat}
                    {this.state.longitude}
                </Typography>
              </div>
            </CardContent>
          </Card>
      </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(Emergency);
