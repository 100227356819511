import React from 'react';
import './App.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import InfoIcon from '@material-ui/icons/Info';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { HashRouter, Switch } from 'react-router-dom';
import { NavLink, Redirect } from 'react-router-dom';
import grey from "@material-ui/core/colors/grey"
import Emergency from "./views/Emergency/Emergency";
import Help from "./views/Help/Help";
import Finder from "./views/Finder/Finder";
import { deepPurple } from '@material-ui/core/colors';
import 'typeface-open-sans';

const useStyles = {
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  root: {
    backgroundColor: deepPurple[500],
    width: "100%",
    bottom: 0,
    position: "fixed",
      
  },
};


const buttonStyle = makeStyles({
  root:{
    color: grey[200],
    "&$selected": {
      color: grey[50],
    }
  },
  selected: {
  },
  })


function App(props) {
  const buttonClasses = buttonStyle();

  const { classes } = props;
  const [value, setValue] = React.useState(1);
    return (
    <div className={classes.whole} >
    <BrowserRouter>
      
      <Route path="/Emergency" component={Emergency}/>
      <Redirect exact from="/" to="/Emergency" />
      <Route path="/Help" component={Help}/>
      <Route path="/Finder" component={Finder}/>
    <BottomNavigation
    value={value}
    onChange={(event, newValue) => {
      setValue(newValue);
    }}
    showLabels
    className={classes.root} >
    <BottomNavigationAction classes={buttonClasses} label="Map" component={Link} to="/Finder" icon={<MapIcon />} />
    <BottomNavigationAction classes={buttonClasses} component={Link} to="/Emergency" label="Emergency" icon={<PhoneIcon />} />
    <BottomNavigationAction classes={buttonClasses} component={Link} to="/Help" label="CPR" icon={<InfoIcon />} />
  </BottomNavigation>
  </BrowserRouter>
  </div>
    ) 
}
export default withStyles(useStyles)(App)
